@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@500&family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

body {
  font-family: "Noto Sans KR", system-ui, -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  line-height: 1.8;
}

.main-content{
  line-height: initial;
  p {
    line-height: 1.8;
  }
  code{
    font-family: "Fira Code", "SFMono-Regular", menlo, consolas, monospace;
    font-weight: 500;
    font-size: 0.75em;
    line-height: 2;
  }
  h3{
    margin-bottom: 0.8em;
  }
}

.nav-list-link{
  font-weight: 400;
}
.active > .nav-list-link{
  font-weight: 700;
}

div.table-wrapper{
  box-shadow: none;
  border-collapse: collapse;
}

th, td{
  border-left: none;
}